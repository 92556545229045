<template>
  <div>
    <header>
      <div class="logo">
        <p class="logoimg"></p>
        <p class="logoname">Rápido Crédito</p>
      </div>

      <nav>
        <a href="#home">Inicio</a>
        <a href="#product">producto</a>
        <a href="#how">Cómo funciona</a>
        <a href="#features">Características</a>
        <a href="#privacy">Política de privacidad</a>
        <a href="#about">Sobre nosotros</a>
      </nav>
    </header>
    <div class="home" id="home">
      
      <div class="mytitle">
        <a
        target="view_window"
        href="https://play.google.com/store/apps/details?id=com.okey.prestamo"
        class="googlelogo"
        style="margin-bottom:50px"
      ></a>
        <div class="title0">Rápido Crédito</div>
        <div class="title1">Más ofertas, más efectivo</div>
      </div>
      
    </div>
    <div class="product" id="product">
      <p class="title">Nuestro producto</p>
      <div class="productlist">
        <div class="productitem">
          <img src="../assets/images/1@3x.png" alt />
          <p class="itemmsg">
            Unsecured loans, CURP required
            <br />Préstamos sin garantía, se requiere CURP
          </p>
        </div>
        <div style="margin: 0 70px" class="productitem">
          <img src="../assets/images/2@3x.png" alt />
          <p class="itemmsg">
            Protección de la intimidad
            <br />No filtrará sus datos personales
          </p>
        </div>
        <div class="productitem">
          <img src="../assets/images/3@3x.png" alt />
          <p class="itemmsg">
            Alta eficiencia
            <br />Aprobación rápida, llegada rápida
          </p>
        </div>
      </div>
    </div>

    <div class="features" id="features">
      <p class="title">Features</p>
      <div class="companyitem">
       
        <div class="right">
          <img src="../assets/images/features.png" alt />
         <p class="msg">
            Un importe de préstamo muy elevado
            <br />Resolver sus problemas financieros
            <br />Confidencialidad de datos segura y fiable
            <br />Siempre que tengas una CURP, podrás iniciar tu viaje de préstamo
            <br />
          </p>
        </div>
        <div class="left">
          <img src="../assets/images/work.png" alt />
          <p class="msg">
              Descargue Rápido Crédito desde Google Play Store
              <br />Regístrate y entra en Rápido Crédito
              <br />Elige tu producto financiero favorito
              <br />Procesamiento exprés de tu pedido de préstamo en 24 hora
              <br />
            </p>
        </div>
      </div>
    </div>
    
    <div class="privacy" id="privacy">
      <p class="title">Política de privacidad</p>
      <div :class="!showmore ? 'privacymsg showmore' : 'privacymsg'">
        <div>

El desarrollador de Rápido Crédito es Rápido Crédito Desarrollador. Rápido Crédito (en adelante, "nosotros") recopila parte de su información, y respetamos y nos comprometemos a proteger su privacidad. Por lo tanto, hemos compilado esta Política de privacidad ("Política de privacidad") para explicarle cómo recopilamos, usamos, almacenamos, protegemos y eliminamos los datos personales que nos proporciona.<br>
Para comprender claramente el contenido relevante de esta política, debe leer detenidamente el texto completo de esta política antes de utilizar los productos o servicios relevantes.<br><br>

Para brindarle nuestros servicios, le pediremos que proporcione la información personal necesaria. <br><br>
<strong> 1.Información SMS</strong><br>
Rápido Crédito necesita obtener la información del SMS del usuario (que incluye: título del SMS, contenido del SMS, si leerlo) cuando el usuario firma un préstamo para evaluar el valor del usuario y el análisis antifraude; Rápido Crédito otorga gran importancia a la seguridad de los datos del usuario y la plataforma carga los datos del usuario en https://oy.oyeqctus.com/oye-gp/, y utiliza el cifrado DES y AES para almacenar y garantizar la seguridad de los datos del usuario. Rápido Crédito nunca compartirá información de los usuarios con terceros.<br><br>

<strong> 2.Información de la lista de contactos</strong><br>
Rápido Crédito necesita completar la información de contacto cuando el usuario solicita un préstamo, y en este momento necesita leer la información de contacto del usuario (incluido el nombre, el número de teléfono móvil). Se utiliza para identificar si el usuario es genuino o no. Los datos cargados son cifrados y almacenados por des y AES, y se cargan en https://oy.oyeqctus.com/oye-gp/ después del cifrado. Para garantizar la seguridad de los datos, esta información no se compartirá con terceros.
<br><br>

<strong> 3.Informacion personal</strong><br>
Incluyendo nombre, tarjeta de identificación, número de teléfono móvil, esta información se utilizará para evaluar los riesgos potenciales y optimizar la calidad de nuestro servicio.<br><br>

<strong> 4.Información de la cuenta</strong><br>
Una vez que se aprueba la solicitud de préstamo, se debe recopilar la información de la cuenta bancaria del usuario para la emisión precisa del préstamo.<br><br>

<strong> 5.Permiso de cámara</strong><br>
Algunas funciones requieren tomar fotos y reconocimiento facial. Este permiso ayuda a los usuarios a cargar los datos de fotos requeridos durante el proceso de préstamo.<br><br>

<strong> 6.Localización geográfica</strong><br>
Obtener información aproximada sobre su ubicación geográfica sólo cuando el usuario utiliza la aplicación facilita la prestación de algunos servicios regionales.<br><br>

<strong> 7.Información del dispositivo</strong><br>
Incluyendo SSAID, dirección MAC, IMEI, modelo de hardware, versión del sistema operativo, etc., para garantizar la seguridad y legalidad de los usuarios que utilizan este producto.<br><br>

<strong> 8.Historial de llamadas</strong><br>
Cuando un usuario se registra o inicia sesión con un código de verificación de voz en Rápido Crédito, Rápido Crédito necesita obtener el permiso de registro de llamadas del usuario para confirmar que la tarjeta SIM utilizada por el usuario para recibir el código de verificación está con el dispositivo. Los datos recopilados serán encriptados y subidos a https://oy.oyeqctus.com/oye-gp/, Rápido Crédito respeta la privacidad de los usuarios, y nunca los revelará a ningún individuo o tercero sin el permiso del usuario
<br><br>

<strong> 9.Lista de aplicaciones</strong><br>
Para garantizar la seguridad financiera, es necesario recoger toda la información de instalación de aplicaciones del usuario.<br><br>

<strong> 10.Permisos de calendario</strong><br>
Cuando los usuarios abren la aplicación, necesitan permiso para acceder al calendario para garantizar la puntualidad de los préstamos financieros.<br><br>

<strong>Cómo usar</strong><br>
Usamos la información que recopilamos, incluida su información personal, información del dispositivo, etc., para brindar y mejorar nuestros servicios, desarrollar nuevos productos y mejorar y personalizar su experiencia en nuestras aplicaciones.Nos comprometemos a utilizar su información de acuerdo con las leyes y reglamentos. <br><br>

<strong>Finalidad de la recogida de datos personales</strong><br>
Verifica tu identidad y garantiza la seguridad de tu cuenta<br>
Identificar riesgos potenciales<br>
Para proporcionarle nuestros servicios y mejorar nuestros servicios<br>
Verificación, inspección, análisis y evaluación de su elegibilidad como prestatario o prestamista.<br><br>

<strong>Cómo almacenamos y protegemos su información personal</strong><br>
Transferiremos su información personal encriptada a https://oy.oyeqctus.com/oye-gp/ de acuerdo con las leyes y regulaciones aplicables y tomaremos todas las medidas y acciones razonables para evitar la pérdida, el uso indebido o la alteración no autorizados. La confidencialidad de sus datos personales es muy importante para nosotros, haremos todo lo posible para proteger la seguridad de su información personal, no tiene que preocuparse por la divulgación de información personal.<br><br>

<strong>Tus derechos</strong><br>
Si su información personal está inactiva durante un período prolongado, también eliminaremos su información personal de vez en cuando en función de cuánto tiempo esté activo en nuestra aplicación, y su información no se transferirá a otros sitios web. También puede tomar la iniciativa de contactarnos (Correo electrónico: dfssdf4353@gmail.com) para eliminar su información personal.<br><br>

<strong>Cambios a esta política</strong><br>
Esta Política de Privacidad puede ser modificada y/o actualizada de vez en cuando. Le recomendamos que lo lea detenidamente y consulte esta página de vez en cuando para ver si hay cambios. Al hacer clic en Aceptar, o si continúa accediendo y utilizando el Servicio después de nuestro aviso de renovación, acepta los cambios (si los hay) a esta Política de privacidad.
<br><br>
<strong>Contáctenos</strong><br>
Si encuentra algún problema durante el uso, puede comunicarse con nosotros a través de dfssdf4353@gmail.com y le responderemos después de una revisión cuidadosa<br><br>
    </div>
        <!-- <p v-show="showmore" @click="showmore = !showmore" class="show"></p> -->
      </div>
    </div>
    <div class="about" id="about">
      <p class="title">Sobre nosotros</p>
      <p class="aboutmsg">
        Rápido Crédito es una solicitud de préstamo online cómoda y segura. Todo el
        proceso de préstamo se puede realizar a través de un smartphone en
        cualquier momento y lugar. Protegemos su privacidad mediante el uso de
        tecnología de control de riesgo de big data para realizar la evaluación
        crediticia de los usuarios, además de proporcionar servicios de
        evaluación crediticia en línea de alta calidad y servicios de préstamos
        rápidos. About us es su plataforma de préstamos ideal.
      </p>
    </div>
    <footer>
      <p class="title">Contact Us</p>
      <div class="contact">
        <p>
          Dirección:
          <br />Juan San Millán, Ángel Flores, 81048 Guasave, Sin.
        </p>
        <p>
          Correo electrónico:
          <br />dfssdf4353@gmail.com
        </p>
      </div>
      <p class="end">@Copyright by Rápido Crédito</p>
    </footer>
  </div>
</template>
<style lang="less">
a {
  text-decoration: none;
}
strong {
  font-weight: 600;
}
.title {
  padding: 150px 0;
  font-family: Helvetica-Bold;
  font-size: 24px;
  color: #7a40ee;
  text-align: center;
  font-weight: 600;
  // border-bottom: 5px solid #7a40ee;
  
}

header {
  width: 100%;
  height: 80px;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #7a40ee;
  color: #fff;
  z-index: 1000000000000;
  line-height: 80px;

  .logo {
    margin-left: 167px;
    float: left;

    .logoimg {
      margin-top: 15px;
      width: 48px;
      height: 48px;
      background: url(../assets/images/logo@3x.png) no-repeat;
      background-size: 100%;
      float: left;
    }

    .logoname {
      font-family: Helvetica-Bold;
      font-size: 16px;
      color: #fff;
      float: left;
      margin-left: 20px;
      font-weight: 600;
    }
  }

  nav {
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 167px;

    a {
      margin: 0 40px;
      font-family: Helvetica-Bold;
      font-size: 18px;
      color: #fff;
      text-align: left;
      text-align: center;
      /* border-bottom: 2px solid #fff; */
    }

    a:hover {
      color: #e25f31;
      /* border-bottom: 2px solid rgba(61,76,233); */
      transition: 0.3s;
    }
  }
}

.home {
  background: url(../assets/images/banner.png) no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 1200px;
  overflow: hidden;
  margin-top: 70px;

  img {
    width: 700px;
    margin-top: 500px;
    margin-left: 150px;
  }

  .lbr {
    width: 80px;
    height: 16px;
    background-color: #fff;
    margin: 60px 0;
    margin-left: 150px;
  }

  .googlelogo {
    display: block;
    background: url(../assets/images/Googleplay.png) no-repeat;
    background-size: 100%;
    width: 376px;
    height: 112px;
    margin-top: 80px;
    margin-left: 150px;
  }
  .mytitle {
    width: 700px;
    margin-top: 300px;
    margin-left: 150px;

    .title0 {
      font-size: 60px;
      font-family:'Courier New', Courier, monospace;
      font-weight: 800;
      color: #000;
      line-height: 40px;
      margin-bottom: 50px;
    }

    .title1 {
      font-size: 40px;
      font-family:'Courier New', Courier, monospace;
      font-weight: 800;
      color: #000;
      line-height: 40px;
      margin-bottom: 50px;
    }
  }
}

.product {
  .productlist {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 410px;

    .productitem {
      float: left;
      text-align: center;
      width: 460px;
      height: 300px;
      padding: 20px;
      background-color: #fff;
      box-shadow: 0 2px 30px 0 rgba(220, 220, 220, 0.5);
      border-radius: 30px;

      img {
        width: 100px;
        height: 100px;
        margin-top: 30px;
      }

      .itemmsg {
        font-family: Helvetica;
        font-size: 14px;
        color: #333333;
        text-align: center;
        line-height: 40px;
        margin-top: 10px;
      }
      .itemmsg:nth-child(0){
        font-size: 20px;
      }
    }
    .productitem:hover{
      background: #f4f4f4;
      cursor: pointer;
    }
  }
}

.company {
  padding: 0 280px;
  margin-top: 180px;

  .companylist {
    .companyitem {
    

      .left {
        img {
          width: 586px;
          height: 414px;
        }
        .msg {
          max-width: 540px;
          font-family: Helvetica;
          font-size: 20px;
          color: #333333;
          text-align: left;
          line-height: 60px;
        }
      }

      .right {
        img {
          width: 586px;
          height: 414px;
        }
        .msg {
          max-width: 540px;
          font-family: Helvetica;
          font-size: 20px;
          color: #333333;
          text-align: left;
          line-height: 60px;
        }
      }
    }
  }
}

.features {
  padding: 0 200px;
  margin-top: 180px;
  color: #7a40ee;
  .companyitem {
    height: 600px;
    margin-bottom: 300px;

    .left {
      float: left;
        img {
          width: 586px;
          height: 414px;
        }
        .msg {
          max-width: 540px;
          font-family: Helvetica;
          font-size: 20px;
          color: #7a40ee;
          text-align: left;
          line-height: 60px;
        }
      }

      .right {
        float: right;
        img {
          width: 586px;
          height: 414px;
        }
        .msg {
          max-width: 540px;
          font-family: Helvetica;
          font-size: 25px;
          color: #7a40ee;
          text-align: left;
          line-height: 60px;
        }
      }
  }
}

.about {
  height: 500px;
  padding: 80px 180px;
  background: linear-gradient(270deg, #7a40ee 0%, #556bfc 100%);
  text-align: center;

  .title {
    font-family: Helvetica-Bold;
    font-size: 44px;
    color: #ffffff;
    padding: 0;
    padding-bottom: 60px;
  }

  .aboutmsg {
    font-family: Helvetica;
    font-size: 24px;
    color: #ffffff;
    line-height: 60px;
    text-align: left;
  }
}

.privacy {
  padding: 0px 210px;
  padding-bottom: 100px;
  position: relative;

  .title {
    font-weight: 600;
    padding: 80px 0;
    font-size: 22px;
  }

  .privacymsg {
    font-family: Helvetica;
    font-size: 20px;
    // height: 550px;
    overflow: hidden;
    text-align: left;
    line-height: 50px;

    p {
      font-weight: 600;
      font-size: 20px;
      margin-top: 40px;
      text-align: center;
    }

    .show {
      background: url(../assets/images/getMore.png) no-repeat;
      background-size: 100%;
      width: 60px;
      height: 60px;
      position: absolute;
      top: 750px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  .showmore {
    height: auto;
    overflow: auto;
  }
}

footer {
  height: 350px;
  background: #2f2f2f;
  color: #fff;

  .title {
    color: #fff;
    padding: 80px 0;
  }

  .contact {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 18px;
    color: #ffffff;
    text-align: left;

    p {
      font-family: Helvetica;
      font-size: 18px;
      color: #ffffff;
      text-align: left;
      line-height: 31px;
    }
  }

  .end {
    width: 100%;
    text-align: center;
    font-family: Helvetica;
    font-size: 14px;
    color: #ffffff;
    margin-top: 60px;
  }
}
</style>
<script>
export default {
  name: "Home",
  data() {
    return {
      showmore: true,
    }
  },
}
</script>
